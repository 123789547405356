import React from "react"

import Layout from "../components/layout-pt"
import SEO from "../components/seo"
import GoogleMapReact from "google-map-react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarker } from "@fortawesome/free-solid-svg-icons"

const AnyReactComponent = ({ text }) => (
  <div
    style={{
      textAlign: "center",
      opacity: ".8",
    }}
  >
    <FontAwesomeIcon icon={faMapMarker} size="3x" />
  </div>
)

const LocationPage = () => (
  <Layout>
    <SEO title="Localização" />
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-4">Localização</h1>
          <h2 class="subtitle">Como nos encontrar</h2>
        </div>
      </div>
    </section>
    <div class="section" style={{ "padding-top": "1rem" }}>
      <div class="columns">
        <div class="column is-1" />
        <div class="column">
          <div class="content">
            <p>
              A cidade de Alcobaça está a 100 km a Norte de Lisboa. É servida
              por 2 auto-estradas (A1 e A8) e por várias estradas nacionais.{" "}
            </p>
            <p>
              A Silveira está situada a 3 km a Sul de Alcobaça, na EN 8-6 em
              direcção de Évora de Alcobaça, Turquel, Benedita.{" "}
            </p>
            <p>
              A partir da Silveira, é fácil deslocar-se em redor, e alternar
              visitas do património histórico (mosteiros de Alcobaça e Batalha,
              castelo de Leiria, vila fortificada de Óbidos, banhos de mar ou
              passeios pedestres no Parque Natural das Serras de Aire e
              Candeeiros.{" "}
            </p>
            <h4>Caravanas e autocaravanas</h4>
            <p>
              Recomendamos às caravanas e autocaravanas, especialmente se forem
              compridas, que entrem no parque de campismo com o portão à direita.
              Vindo de Alcobaça a entrada encontra-se à sua esquerda, continue
              até à primeira rotunda (1 km) e faça inversão de marcha.{" "}
            </p>
            <p><strong>A receção está aberta das 8:30 às 20:30.</strong></p>
            <br></br>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-image">
              <div style={{ height: "50vh", width: "100%" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyBD0DW9vSvjU7XrcHvhuRG2OQmTGmJonqg",
                  }}
                  defaultCenter={{
                    lat: 39.527153,
                    lng: -8.964215,
                  }}
                  defaultZoom={12}
                >
                  <AnyReactComponent
                    lat={39.527153}
                    lng={-8.964215}
                    text="Camping Silveira"
                  />
                </GoogleMapReact>
              </div>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Morada</p>
                </div>
              </div>
              <div class="content">
                <p>
                  Silveira, Capuchos <br />
                  2460-479 Alcobaça <br />
                  Portugal
                </p>
                <a
                  href="https://goo.gl/maps/uwZkUvcQJRShSh7G6"
                  class="button is-primary"
                >
                  Clique aqui para Google Maps
                </a>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div class="column is-1" />
      </div>
    </div>
  </Layout>
)

export default LocationPage
